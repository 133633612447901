<template>
  <Page color="grey" title="Kalender-Abos">
    <v-alert
      type="info"
      :prominent="!$vuetify.breakpoint.xs"
      text
      border="left"
    >
      Die folgenden Kalender kannst du <b>abonnieren</b>!
      <ul>
        <li>
          Klicke dazu auf den Eintrag, so dass der Link in die Zwischenablage
          kopiert wird.
        </li>
        <li>
          Nun kannst du in deiner Kalender-App den Link als «Web-Kalender-Abo»
          hinzufügen.
        </li>
        <li>
          Mit dem Abo wird der Kalender automatisch regelmässig aktualisiert
          (Intervall je nach App einstellbar)
        </li>
      </ul>
    </v-alert>
    <v-row>
      <v-col>
        <v-card class="mb-4" :loading="loading"
          ><v-system-bar>geschützte ICal-Abos</v-system-bar>

          <template v-if="icalSecret">
            <v-card-text>
              Die folgenden Kalender-Abos sind durch ein persönliches Geheimnis
              geschützt und beinhalten persönliche Informationen.<br />Teilweise
              können sie mit einem vorangestellten Text (oder auch Emoji)
              versehen werden. (Dazu rechts den Text setzen)
            </v-card-text>
            <v-divider></v-divider>
            <v-list>
              <v-list-item
                @click="
                  copyToClipboard(
                    getIcalRoute('exam', true, { prepend: prepend.exam })
                  )
                "
              >
                <v-list-item-icon
                  ><v-icon color="danger"
                    >mdi-octagram</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-subtitle>meine Proben</v-list-item-subtitle>
                  <pre>{{
                    getIcalRoute("exam", true, { prepend: prepend.exam })
                  }}</pre>
                </v-list-item-content>
                <v-list-item-action>
                  <!-- prettier-ignore-attribute -->
                  <v-chip
                    :outlined="!!prepend.exam"
                    :close="!!prepend.exam"
                    @click:close="prepend.exam=''"
                    @click.stop="
                      (async () => {
                        var result = await $root.prompt({message:'Wird jedem Eintrag vorangestellt!', value:prepend.exam});
                        if (result) {
                          prepend.exam = result;
                        }                        
                      })
                    "
                    >{{ prepend.exam ? prepend.exam : "nicht gesetzt" }}</v-chip
                  >
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                @click="
                  copyToClipboard(
                    getIcalRoute('homework', true, {
                      prepend: prepend.homework,
                    })
                  )
                "
              >
                <v-list-item-icon
                  ><v-icon color="info"
                    >mdi-progress-check</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-subtitle
                    >meine Hausaufgaben</v-list-item-subtitle
                  >
                  <pre>{{
                    getIcalRoute("homework", true, {
                      prepend: prepend.homework,
                    })
                  }}</pre>
                </v-list-item-content>

                <v-list-item-action>
                  <!-- prettier-ignore-attribute -->
                  <v-chip
                    :outlined="!!prepend.homework"
                    :close="!!prepend.homework"
                    @click:close="prepend.homework=''"
                    @click.stop="
                      (async () => {
                        var result = await $root.prompt({message:'Wird jedem Eintrag vorangestellt!', value:prepend.homework});
                        if (result) {
                          prepend.homework = result;
                        }                        
                      })
                    "
                    >{{
                      prepend.homework ? prepend.homework : "nicht gesetzt"
                    }}</v-chip
                  >
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                @click="
                  copyToClipboard(
                    getIcalRoute('info', true, {
                      prepend: prepend.info,
                    })
                  )
                "
              >
                <v-list-item-icon
                  ><v-icon color="info"
                    >mdi-alert-outline</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-subtitle>meine Hinweise</v-list-item-subtitle>
                  <pre>{{
                    getIcalRoute("info", true, {
                      prepend: prepend.info,
                    })
                  }}</pre>
                </v-list-item-content>

                <v-list-item-action>
                  <!-- prettier-ignore-attribute -->
                  <v-chip
                    :outlined="!!prepend.info"
                    :close="!!prepend.info"
                    @click:close="prepend.info=''"
                    @click.stop="
                      (async () => {
                        var result = await $root.prompt({message:'Wird jedem Eintrag vorangestellt!', value:prepend.info});
                        if (result) {
                          prepend.info = result;
                        }                        
                      })
                    "
                    >{{ prepend.info ? prepend.info : "nicht gesetzt" }}</v-chip
                  >
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                @click="
                  copyToClipboard(
                    getIcalRoute('lesson', true, {
                      prepend: prepend.lesson,
                    })
                  )
                "
              >
                <v-list-item-icon
                  ><v-icon color="info"
                    >mdi-account-details</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-subtitle>meine Lektionen</v-list-item-subtitle>
                  <pre>{{
                    getIcalRoute("lesson", true, {
                      prepend: prepend.lesson,
                    })
                  }}</pre>
                </v-list-item-content>
                <v-list-item-action>
                  <!-- prettier-ignore-attribute -->
                  <v-chip
                    :outlined="!!prepend.lesson"
                    :close="!!prepend.lesson"
                    @click:close="prepend.lesson=''"
                    @click.stop="
                      (async () => {
                        var result = await $root.prompt({message:'Wird jedem Eintrag vorangestellt!', value:prepend.lesson});
                        if (result) {
                          prepend.lesson = result;
                        }                        
                      })
                    "
                    >{{
                      prepend.lesson ? prepend.lesson : "nicht gesetzt"
                    }}</v-chip
                  >
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                v-if="$_hasRole('teacher')"
                @click="copyToClipboard(getIcalRoute('event', true))"
              >
                <v-list-item-icon
                  ><v-icon color="success"
                    >mdi-calendar</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-subtitle>Termine</v-list-item-subtitle>
                  <pre>{{ getIcalRoute("event", true) }}</pre>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="$_hasRole('teacher')"
                @click="
                  copyToClipboard(getIcalRoute('event', true, { division: 1 }))
                "
              >
                <v-list-item-icon
                  ><v-icon color="success"
                    >mdi-calendar</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-subtitle>Termine GH</v-list-item-subtitle>
                  <pre>{{ getIcalRoute("event", true, { division: 1 }) }}</pre>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="$_hasRole('teacher')"
                @click="
                  copyToClipboard(getIcalRoute('event', true, { division: 2 }))
                "
              >
                <v-list-item-icon
                  ><v-icon color="success"
                    >mdi-calendar</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-subtitle>Termine MN</v-list-item-subtitle>
                  <pre>{{ getIcalRoute("event", true, { division: 2 }) }}</pre>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="$_hasRole('teacher')"
                @click="
                  copyToClipboard(getIcalRoute('event', true, { division: 3 }))
                "
              >
                <v-list-item-icon
                  ><v-icon color="success"
                    >mdi-calendar</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-subtitle>Termine WR</v-list-item-subtitle>
                  <pre>{{ getIcalRoute("event", true, { division: 3 }) }}</pre>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-card-text>
              Hier findest du dein Geheimnis. Du kannst dieses löschen und neu
              generieren. Du musst dann aber die Links in den Kalender-Abos
              entsprechend anpassen.
            </v-card-text>

            <v-list subheader>
              <v-list-item @click="copyToClipboard(icalSecret)">
                <v-list-item-icon><v-icon>mdi-lock</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle>Geheimnis</v-list-item-subtitle>
                  <pre>{{ icalSecret }}</pre>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </template>
          <template v-else>
            <v-alert class="mb-0" tile text type="error">
              Erstelle zuerst ein «Geheimnis» über den «Generieren»-Knopf.
            </v-alert>
          </template>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="danger"
              @click="removeIcalSecret"
              :disabled="!icalSecret"
              >Geheimnis löschen</v-btn
            ><v-btn
              text
              color="primary"
              @click="addIcalSecret"
              :disabled="!!icalSecret"
              >Geheimnis generieren</v-btn
            ></v-card-actions
          >
        </v-card>

        <v-card class="mb-4"
          ><v-system-bar>öffentliche ICal-Abos</v-system-bar>

          <template>
            <v-card-text>
              Die folgenden Kalender-Abos sind öffentlich verfügbar.
            </v-card-text>
            <v-divider></v-divider>
            <v-list>
              <v-list-item
                @click="
                  copyToClipboard(
                    getIcalRoute('holiday', false, { icon: prepend.holiday })
                  )
                "
              >
                <v-list-item-icon
                  ><v-icon color="warning"
                    >mdi-calendar</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-subtitle>Ferien</v-list-item-subtitle>
                  <pre>{{
                    getIcalRoute("holiday", false, { icon: prepend.holiday })
                  }}</pre>
                </v-list-item-content>
                <v-list-item-action>
                  <v-switch
                    @click.stop
                    inset
                    v-model="prepend.holiday"
                    label="mit icon"
                  ></v-switch>
                </v-list-item-action>
              </v-list-item>

              <v-list-item
                @click="copyToClipboard(getIcalRoute('event', false))"
              >
                <v-list-item-icon
                  ><v-icon color="success"
                    >mdi-calendar</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-subtitle
                    >Termine öffentlich</v-list-item-subtitle
                  >
                  <pre>{{ getIcalRoute("event", false) }}</pre>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                @click="
                  copyToClipboard(getIcalRoute('event', false, { division: 1 }))
                "
              >
                <v-list-item-icon
                  ><v-icon color="success"
                    >mdi-calendar</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-subtitle
                    >Termine öffentlich GH</v-list-item-subtitle
                  >
                  <pre>{{ getIcalRoute("event", false, { division: 1 }) }}</pre>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                @click="
                  copyToClipboard(getIcalRoute('event', false, { division: 2 }))
                "
              >
                <v-list-item-icon
                  ><v-icon color="success"
                    >mdi-calendar</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-subtitle
                    >Termine öffentlich MN</v-list-item-subtitle
                  >
                  <pre>{{ getIcalRoute("event", false, { division: 2 }) }}</pre>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                @click="
                  copyToClipboard(getIcalRoute('event', false, { division: 3 }))
                "
              >
                <v-list-item-icon
                  ><v-icon color="success"
                    >mdi-calendar</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-subtitle
                    >Termine öffentlich WR</v-list-item-subtitle
                  >
                  <pre>{{ getIcalRoute("event", false, { division: 3 }) }}</pre>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </template>
        </v-card>
      </v-col>
    </v-row>
    <v-alert
      :prominent="!$vuetify.breakpoint.xs"
      text
      border="left"
      type="info"
    >
      Die obenstehenden Kalender können auch <b>heruntergeladen</b> werden:
      <ul>
        <li>
          Klicke dazu auf den Eintrag, so dass der Link in die Zwischenablage
          kopiert wird.
        </li>
        <li>
          Füge den Eintrag einfach in die Adresszeile deines Browsers ein.
        </li>
      </ul>
    </v-alert>

    <v-alert
      :prominent="!$vuetify.breakpoint.xs"
      text
      border="left"
      type="error"
    >
      <b>Aber Achtung!</b><br />So heruntergeladene und importierte Termine
      passen sich nicht automatisch an.<br />Wenn also ein Termin abgesagt wird
      oder eine Lektion ausfällt wird das nicht automatisch bermerkt!
    </v-alert>
  </Page>
</template>
<script>
import { defineComponent } from "vue";
import { copyToClipboard } from "common/utils/helper.js";

function serialize(obj) {
  var str = [];
  for (var p in obj) {
    if (obj[p]) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  }
  return str.join("&");
}

export default defineComponent({
  components: {},
  name: "ICalendar",
  data() {
    return {
      count: 0,
      icalSecret: false,
      loading: false,
      prepend: {
        exam: "Probe:",
        homework: "Hausaufgabe:",
        info: "Hinweis:",
        lesson: "",
        holiday: true,
      },
    };
  },
  watch: {
    prepend: {
      deep: true,
      handler() {
        window.localStorage.setItem(
          "icalPrepend",
          JSON.stringify(this.prepend)
        );
      },
    },
  },
  methods: {
    copyToClipboard,
    getIcalRoute(resource, addSecret, queryDict) {
      const queryString = serialize(
        addSecret
          ? {
              ...queryDict,
              account: this.$_profilePerson.account,
              secret: this.icalSecret,
            }
          : queryDict
      );
      return `${this.apiBase()}ical/${resource}/${
        queryString ? "?" + queryString : ""
      }`;
    },
    async getIcalSecret() {
      this.loading = true;
      const data = await this.apiGet({
        resource: "account/icalsecret",
        id: this.$_profilePerson.account,
      });
      this.icalSecret = data.icalSecret;
      this.loading = false;
    },
    async removeIcalSecret() {
      if (
        await this.$root.confirm({
          message: `Das Geheimnis wird gelöscht. Bist du sicher? Du kannst dann ein neues generieren.`,
          color: "danger",
          icon: "mdi-lock",
        })
      ) {
        this.loading = true;
        await this.apiPatch({
          resource: "account/icalsecret",
          id: this.$_profilePerson.account,
          key: "icalSecret",
          value: "delete",
        });
        await this.getIcalSecret();
        this.loading = false;
      }
    },
    async addIcalSecret() {
      if (
        await this.$root.confirm({
          message: `Ein Geheminis wird generiert und kann dann für den ICal-Zugriff verwendet werden.`,
          color: "success",
          icon: "mdi-lock",
        })
      ) {
        this.loading = true;
        await this.apiPatch({
          resource: "account/icalsecret",
          id: this.$_profilePerson.account,
          key: "icalSecret",
          value: "create",
        });
        await this.getIcalSecret();
        this.loading = false;
      }
    },
  },
  async created() {
    this.getIcalSecret();

    if (window.localStorage.getItem("icalPrepend")) {
      this.prepend = JSON.parse(window.localStorage.getItem("icalPrepend"));
    }
  },
});
</script>
<style lang="css" scoped>
pre {
  font-size: small;
}
</style>
